:root {
  --gap-quarter: 0.25rem;
  --gap-half: 0.5rem;
  --gap: 1rem;
  --gap-double: 2rem;

  --bg: #fff;
  --fg: #000;
  --accents-1: #111;
  --accents-2: #333;
  --accents-3: #444;
  --accents-4: #FAFAFA;
  --accents-soft: #EEE;
  --red: #c10000;
  --geist-foreground: #000;
  --christmas-background: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);

  --radius: 8px;

  --font-sans: -apple-system,BlinkMacSystemFont,YakuHanJP,"Hiragino Kaku Gothic ProN",Meiryo,sans-serif;
  --font-mono: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

// DARK THEME

[data-theme="dark"] {
}

[data-theme="dark"] {
    --bg: #121212;
    --fg: #EEE;
    --accents-soft: #DDD;
    --accents-1: #CCC;
    --accents-2: #AAA;
    --accents-3: #777;
    --accents-4: #333;
    --geist-foreground: #FFF;
    --christmas-background: linear-gradient(326deg, #31030f 0%, #4e1303 74%);

}

svg {
    fill: var(--fg);
}

@media (prefers-color-scheme: dark) {
    img {
        filter: brightness(.8) contrast(1.2);
    }
}



* {
  box-sizing: border-box;
}

html,
body {
  padding: 0.5em;
  margin: 0;
  font-size: 20px;
}

body {
  min-height: 100vh;
  background: var(--bg);
  color: var(--fg);
  font-family: var(--font-sans);
  /* Hack */
  overflow-x: hidden;
}

h1, h2, h3, p {
    font-family: "Open Sans",sans-serif;
}

h1 {
    margin: 0;
    font-size: 4.4rem;
    font-weight: 300;
}

code[class*='language-'],
pre[class*='language-'] {
  color: #000;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  font-size: 0.95em;
  line-height: 1.4em;
  tab-size: 4;
  hyphens: none;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999;
}
.token.namespace {
  opacity: 0.7;
}
.token.string,
.token.attr-value {
  color: #028265;
}
.token.punctuation,
.token.operator {
  color: #000;
}
.token.url,
.token.symbol,
.token.boolean,
.token.variable,
.token.constant {
  color: #36acaa;
}
.token.atrule,
.language-autohotkey .token.selector,
.language-json .token.boolean,
code[class*='language-css'] {
  font-weight: 600;
}
.language-json .token.boolean {
  color: var(--geist-success);
}
.token.keyword {
  color: #ff0078;
  font-weight: bolder;
}
.token.function,
.token.tag,
.token.class-name,
.token.number,
.token.tag .token.punctuation {
  color: var(--geist-success);
}
.language-autohotkey .token.tag {
  color: #9a050f;
}
.token.selector,
.language-autohotkey .token.keyword {
  color: #00009f;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.deleted {
  color: red;
  font-weight: bolder;
}
.token.inserted {
  color: var(--geist-success);
  font-weight: bolder;
}
.language-json .token.property,
.language-markdown .token.title {
  color: #000;
  font-weight: bolder;
}
.language-markdown .token.code {
  color: var(--geist-success);
  font-weight: normal;
}
.language-markdown .token.list,
.language-markdown .token.hr {
  color: #999;
}
.language-markdown .token.url {
  color: #ff0078;
  font-weight: bolder;
}
.token.selector {
  color: #2b91af;
}
.token.property,
.token.entity {
  color: #f00;
}
.token.attr-name,
.token.regex {
  color: #d9931e;
}
.token.directive.tag .tag {
  background: #ff0;
  color: #393a34;
}
/* dark */
pre.dark[class*='language-'] {
  color: #fafbfc;
}
.language-json .dark .token.boolean {
  color: var(--geist-success);
}
.dark .token.string {
  color: #50e3c2;
}
.dark .token.function,
.dark .token.tag,
.dark .token.class-name,
.dark .token.number {
  color: #2ba8ff;
}
.dark .token.attr-value,
.dark .token.punctuation,
.dark .token.operator {
  color: #efefef;
}
.dark .token.attr-name,
.dark .token.regex {
  color: #fac863;
}
.language-json .dark .token.property,
.language-markdown .dark .token.title {
  color: #fff;
}
.language-markdown .dark .token.code {
  color: #50e3c2;
}

.links {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.features {
  display: flex;
  flex-wrap: wrap;
  margin: 4rem auto;
  width: 920px;
  max-width: calc(100vw - var(--gap-double));
}

.feature {
  flex: 0 0 25%;
  align-items: center;
  display: inline-flex;
  padding: 0 0.5rem 1.5rem;
  margin: 0 auto;
}

.feature h4 {
  margin: 0 0 0 0.5rem;
  font-weight: 700;
  font-size: 0.95rem;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .feature div {
    flex-basis: auto;
    padding-left: 0;
  }

  .feature h4 {
    font-size: 0.75rem;
  }
}

.explanation {
  font-size: 1rem;
  width: 35rem;
  max-width: 100vw;
  padding: 0 2rem;
  background: var(--bg);
  margin: var(--gap-half) auto;
}
figure {
  font-size: 0.85rem;
  color: #999;
  line-height: 1.8;
}

figure {
  font-size: 0.85rem;
  color: #999;
}

a {
  color: #0070f3;
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  color: #3291ff;
}

.links {
  margin-top: var(--gap);
}

mark {
  padding: var(--gap-quarter);
  border-radius: var(--radius);
  background: rgba(247, 212, 255, 0.8);
}

.title {
  text-align: center;
}

.logo :global(svg) {
  max-width: calc(100vw - var(--gap-double));
}

h1 {
  margin: var(--gap-double) 0 calc(0.5 * var(--gap)) 0;
  font-size: 2.25rem;
  letter-spacing: -0.05rem;
}

h2 {
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: -0.02rem;
  padding-left: 0.5em;
  border-left: 1px solid var(--fg);
}

h3 {
    font-weight: 300;
}

.video {
  width: 1080px;
  max-width: calc(100vw - 40px);
  transform: translateX(-50%);
  margin-left: 50%;
  text-align: center;
  cursor: pointer;
}
.video :global(video) {
  max-width: 100%;
  max-height: 90vh;
  outline: none;
}

p {
  color: #333;
  font-weight: 400;
  font-size: 0.94rem;
  line-height: 1.7;
}

@media (prefers-color-scheme: dark) {
    p {
        color: var(--fg);
    }
}

pre {
  white-space: pre;
}
pre :global(code) {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

code {
  font-size: 0.8rem;
  background: var(--accents-soft);
  color: var(--red);
  padding: 0.2rem;
  border-radius: var(--radius);
  font-family: var(--font-mono);
}

.slice {
  position: relative;
}

.slice::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 130%;
  background: var(--bg);
  transform: skew(-20deg);
}

@media (max-width: 600px) {
  .explanation {
    padding: 0 1rem 4rem;
  }

  h2 {
    font-size: 0.95rem;
    letter-spacing: 0;
  }
}

.dotted {
    border-bottom: 1px dashed var(--fg);
}

footer {
  padding: 2em 0;
  text-align: center;
}

footer img {
  display: block;
  margin: 0.5rem auto;
}

footer span:nth-child(3) {
  color: #777777;
}

footer span:nth-child(3) a {
  color: inherit;
}

.therapist span {
    margin: 0.5em 1em;
    display: inline-block;
    background-color: var(--accents-3);
    color: var(--fg);
    border-radius: 1em 1em 1em 0;
}

.user span {
    margin: 0.5em 1em;
    display: inline-block;
    background-color: #0074d9;
    color: var(--bg);
    border-radius: 1em 1em 0 1em;
}

.user {
    text-align: right;
}
